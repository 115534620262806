import { Component } from 'react';
import  Translate_pl_PL from './Translate_pl_PL.jsx';
class Translate extends Component {
    static Trans = {
        pl_PL:Translate_pl_PL.Trans,
        en_GB:{
            SSO:{
            SSOLoginForm:{
                login:{
                    label:"Login",
                    placeholder:"Login"
                },
                passwd:{
                    label:"Password",
                    placeholder:"Password"
                },
                loginToSystem:{
                    label:"Log in"
                },
                register:{
                    label:"Register"
                },
            }
            },
            EduLeki: {
                EduLekiHomePage: {
                    new_game: "New Game",
                    how_to_play: "How to play",
                    about_us: "About us",
                    change_lang: "Change Lang",
                    login: "Login",
                    register:"Register",

                },
                GameFinish:{
                    new_game:"New Game",
                    back:"Back"
                },
                EduLekiPlayerWinPage:{
                    title:"Player Win"
                },
                EduLekiPlayerLostPage:{
                    title:"Player lost"
                },
                EduLekiPlayerDrawPage:{
                    title:"Is draw"
                },
                EduLekiAboutUsPage: {
                    title: "About us",
text: "We create high-quality educational applications and games for people with disabilities, especially for the visually impaired. Our goal is to make life easier and expand the knowledge of our users. We understand that software development requires financial resources, which is why we need support to continue creating innovative solutions. We are grateful for any help and financial support we receive. Donors who understand the importance of our mission contribute to the development of our projects and enable us to have a maximum impact on the disabled community. By supporting the Edupanel.pl organization, donors become part of our community that strives to eliminate barriers and create a more inclusive world for people with disabilities. We appreciate every form of support as it allows us to continue our mission and provide valuable education to the visually impaired worldwide."
                },
                EduLekiHowToPlayPage: {
                    title: "How to play",
text: "<ol><li>The game starts on a 3x3 board.</li><li>At the beginning of the game, the board is empty.</li><li>Players take turns making moves - first the player, then the AI opponent.</li><li>The player chooses their square by stating the number of the corresponding square (for example, \"Choose square number 1\", \"Choose square number 2\" and so on) or by clicking on the selected square on the screen.</li><li>After selecting a square, the player places their mark on the board. The square is marked as occupied.</li><li>If a player or the AI manages to align three of their marks in a row - horizontally, vertically, or diagonally - the game ends, and the winner is announced.</li><li>If there are no more available squares on the board and no player has aligned three marks in a row, the game ends in a draw.</li><li>After the game ends, the player can decide whether they want to start another round.</li></ol>"
                },
                NoSoundPage:{
                    no_sound:"Press button to play sound"
                }
            }
        },
    };
    static getT(lang, module, page) {
        let TT = Translate.Trans[lang][module][page];
        return TT;
    }
}
export default Translate;
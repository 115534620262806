import  React,{ Component } from 'react';
import BasePage from './BasePage.jsx';
import Menu from './Menu.jsx';
import Tools from '../Tools.jsx';
class BaseSlimPage extends Component {
    constructor(props){
        super();
        this.props=props;

    }
  render() {    
    let isMenuShow=Tools.isset(this.props.isMenuShow)?this.props.isMenuShow:true;
    return (
            <div className='row g-0' style={{backgroundColor:"white",height:"auto"}}>
                <Menu isShow={isMenuShow}></Menu>
                <div className='col-sm-1 col-md-2 col-lg-3'></div>
                <BasePage className='col-12 col-sm-10 col-md-8  col-lg-6'>
                    {this.props.children}
                </BasePage>
               <div className='col-sm-1 col-md-2  col-lg-3'></div>
            </div>
    );
  }
}

export default BaseSlimPage;
import { Component } from 'react';
class TCT_eduleki_Pl_PL extends Component {
    static map = {
        EduLekiHomePage: {
            "nowa gra": {
                "fun": "clickButton",
                "param": "eduleki_new_game"
            },
            "zasady": {
                "fun": "clickButton",
                "param": "eduleki_how_to_play"
            },
            "o nas": {
                "fun": "clickButton",
                "param": "eduleki_about_us"
            }
        },
        EduLekiNewGamePage: {
            "zaznacz": {
                "fun": "selectField",
                "commands": {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                },
            }
        },
        default:{
            "wstecz": {
                "fun": "clickButton",
                "param": "back"
            },
        }
    };

}
export default TCT_eduleki_Pl_PL;
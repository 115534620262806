//import { React } from 'react';
import React, { Component } from 'react';
import TTR_Pl_PL from './TextToRead/TTR_Pl_PL.jsx';
import TTR_En_GB from './TextToRead/TTR_En_GB.jsx';
import Tools from './spxbasecode/Tools.jsx';
class TextToRead extends Component {
  static Reads = {
    pl_PL: TTR_Pl_PL.reads,
    en_GB: TTR_En_GB.reads
  };
  constructor(props) {
    super();
    this.source = null;
    this.sourceList=[];
    this.isInteract=false;
    this.hasLangChange=false;
    this.state = {
      isPlaying: false
    };
    this.props = props;
    this.props.parent.textToRead = this;
    this.audioRef = React.createRef();
    this.module=null;
    this.pageName=null;
    this.tag=null;
  }

  getT(lang, module, page) {
    let TT = TextToRead.Reads[lang][module][page];
    return TT;
  }

  read(module, pageName, tag) {
    this.module=module;
    this.pageName=pageName;
    this.tag=tag;
    let text = this.getT(window.App.lang, module, pageName);
    let this2=this;
    this.sourceList=[];
    tag.forEach(el => {
      let xx=text[el.tag];
      this2.sourceList.push(xx);
    });
    let mp3=this.sourceList[0].mp3;
    this.changeSource(mp3);
    
  }
  play() {
    this.togglePlay();
  }

  togglePlay() {
    if (!window.App.Config.isPlayAudio){
      return;
    }
    if (!this.isInteract){
      return;
    }
    if (Tools.isset(window.App.Config.lastPlaySound)){
      if (new Date() - window.App.Config.lastPlaySound <= 5000){
        return;
      }
    }
    window.App.Config.lastPlaySound=new Date();
    const { isPlaying } = this.state;
    if (isPlaying) {
      this.setState({ isPlaying:false });
      this.audioRef.current.pause();
    } else {
      this.setState({ isPlaying:true });
      this.audioRef.current.play().catch(e=>{
        let MM=["NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD"];
        let MM2=["AbortError: The play() request was interrupted by a new load request. https://goo.gl/LdLk22"];
        if (MM.indexOf(e.toString())>=0){
          window.App.changePage("EduLeki","nosound",null);
          return;
        }
        if (MM2.indexOf(e.toString())>=0){
          return;
        }
        Tools.alert(e.toString());
        
        
      });
    }
    
  }
  changeSource(fileMP3) {
    window.App.textToRead.source = fileMP3;
    window.App.textToRead.forceUpdate();
    window.App.textToRead.play();
    

  }
  handleEnd() {
    //console.log("Koniec odtwarzania");
  }
  render() {
    if (this.hasLangChange){
      this.read(this.module, this.pageName, this.tag);
      this.hasLangChange=false;
    }
    const { isPlaying } = this.state;
    let this2 = this;
    return (
      <div>
        <audio ref={this.audioRef} autoPlay src={this.source} onEnded={this.handleEnd} />

      </div>
    );

  }

}
export default TextToRead;
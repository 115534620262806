import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import BaseSlimPage from '../../../spxbasecode/cmps/BaseSlimPage.jsx';
import BaseAnimation from '../../../spxbasecode/cmps/BaseAnimation.jsx';
import PageWithMenu from '../../../spxbasecode/cmps/PageWithMenu.jsx';
import BaseLabel from '../../../spxbasecode/cmps/BaseLabel.jsx';
import LogoEdupanel from '../../../spxbasecode/cmps/LogoEdupanel.jsx';
import Translate from '../../../Translate.jsx';

class EduLekiLogoPage extends Component {
  constructor() {
    super();
    let this2 = this;
    this.tag="EduLekiLogoPage";
    this.module="EduLeki";
    window.App.readText(this.module,this.tag,[{tag:"text1"}]);
    
  }
  clickButton(pageName) {
    this.openPage(pageName);
  }
  openPage(pageName) {
    window.App.changePage("EduLeki", pageName, null);
  }
  onEndAnimation(){
    window.App.changePage("EduLeki","home", null);
  }
  render() {
    let TT = Translate.getT(window.App.lang, "EduLeki", "LogoPage");
    let time=1000;
    let components = [
     { component: LogoEdupanel, time:1500 },
      {
        component: BaseLabel, time:2500, data: {
          text: 'Przedstawiamy',
          font: 'Arial',
          font_size:'40px',
          color: 'white'
        }
      },
      {
        component: BaseLabel, time: 75000, data: {
          text: 'EDULEKI',
          font: 'Arial',
          font_size:'40px',
          color: 'White'
        }
      }
    ];

    /*
    <BaseSlimPage >
        <div className='row h-100 g-0 no-scroll'>
          <div className="row g-0 h-40"></div>
          <div className='row g-0 h-20'>
            <BaseAnimation components={components} onEnd={this.onEndAnimation}/>
          </div>
          <div className="row g-0 h-40"></div>
        </div>
      </BaseSlimPage>

    */
    return (
        <div style={{backgroundColor:"white",display:"block"}} className='row h-100 g-0 no-scroll'>
          <div className='row h-20'></div>
          <div className='row h-50'>
            <div className='d-hide col-sm-2 col-md-3'></div>
            <BaseAnimation  className='col-xs-12 col-sm-8 col-md-6 g-10 h-100' components={components} onEnd={this.onEndAnimation}/>
          </div>
        </div>
        
    

    );
  }
}

export default EduLekiLogoPage;
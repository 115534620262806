import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import BaseSlimPage from '../../../spxbasecode/cmps/BaseSlimPage.jsx';
import PageWithMenu from '../../../spxbasecode/cmps/PageWithMenu.jsx';
import BaseButton from '../../../spxbasecode/cmps/BaseButton.jsx';
import Translate from '../../../Translate.jsx';

class EduLekiHomePage extends Component {
  constructor(){
    super();
    let this2=this;
    this.tag="EduLekiHomePage";
    this.module="EduLeki";
    window.App.readText(this.module,this.tag,[{tag:"text1"}]);
    setTimeout(function(){
    window.App.textToCommand.listen(this2,this2.tag);
    },500);
  }
  clickButton(pageName){
    this.openPage(pageName);
  }
    openPage(pageName){
        window.App.changePage("EduLeki",pageName,null);
    }
  render() {    
      let TT=Translate.getT(window.App.lang,"EduLeki",this.tag);
    return (
      <BaseSlimPage >
      
      <div className='row h-100 g-0 no-scroll'>
      <div className="row g-0 h-20"></div>
      <div className='row g-0 h-10'>
        <BaseButton label={TT.loadFromFile} onClick={ (event) => this.openPage('eduleki_load_from_file')}></BaseButton>
      </div>
      
      <div className='row g-0 h-10'>
        <BaseButton label={TT.register} onClick={ (event) => this.openPage('eduleki_register')}></BaseButton>
      </div>
      
      <div className='row g-0 h-10'>
        <BaseButton label={TT.loginToSystem} onClick={ (event) => this.openPage('eduleki_login')}></BaseButton>
      </div>
      {/*
      <div className='row g-0 h-10'>
        <BaseButton label={TT.change_lang} onClick={(event) =>window.App.changeLangToNext()}></BaseButton>
      </div>
      <div className='row g-0 h-10'>
        <BaseButton label={TT.about_us}  onClick={(event) =>this.openPage('eduleki_about_us')}></BaseButton>
      </div>
    */}
            <div className="row g-0 h-20"></div>
      </div>
 </BaseSlimPage>
 

    );
    /*
<div className='row g-0 h-5'>
        <BaseButton label={TT.login} onClick={(event) =>this.openPage('eduleki_login')}></BaseButton>
      </div>
      <div className='row g-0 h-5'>
        <BaseButton label={TT.register} onClick={(event) =>this.openPage('eduleki_register')}></BaseButton>
      </div>

    */
  }
}

export default EduLekiHomePage;